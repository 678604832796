<template>
  <LanguageSwitcher
    v-if="isSurveyMultilingual"
    :user-survey-slug="userSurveySlug"
    :languages="languages"
    :first-page-is-orgstructure="firstPageIsOrgstructure"
  />
</template>

<script>
import * as apiService from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import { mapMutations } from 'vuex';
import { toggleLoading, updateProgressBar, setCompanyId } from '@/plugins/vuex/mutationTypes';
import LanguageSwitcher from '@/views/LanguageSwitcher.vue';

export default {

  name: 'SurveyTestTechPage',

  components: { LanguageSwitcher },

  inject: ['Names'],

  data() {
    return {
      userSurveySlug: '',
      languages: [],
      firstPageIsOrgstructure: false,
    };
  },

  computed: {
    surveySlug() {
      return this.$route.params.slug;
    },
    isSurveyMultilingual() {
      return this.languages.length > 1;
    },
    linkTypeOption() {
      return this.$route.query.type || 'auth';
    },
  },

  async created() {
    await this.initSurvey();
    await this.getSurveyAvailableLanguages();
    if (this.isSurveyMultilingual) {
      return;
    }
    if (this.firstPageIsOrgstructure) {
      this.$router.push({
        name: this.Names.R_SURVEY_ORGSTRUCTURE,
        params: { slug: this.userSurveySlug },
        query: {
          testing: true,
          link_type: this.$route.query.type ? this.linkTypeOption : undefined,
        },
      });
      return;
    }
    this.$router.push({
      name: this.Names.R_USER_SURVEY_PAGE,
      params: { slug: this.userSurveySlug },
      query: {
        testing: true,
        link_type: this.$route.query.type ? this.linkTypeOption : undefined,
      },
    });
  },

  methods: {
    ...mapMutations([toggleLoading, updateProgressBar, setCompanyId]),
    async initSurvey() {
      this[toggleLoading](true);

      try {
        const { data: { slug } } = await apiService.surveyByUserSlugCreate(this.surveySlug, {
          params: {
            testing: true,
            link_type: this.$route.query.type ? this.linkTypeOption : undefined,
          },
        });
        this.userSurveySlug = slug;
        const { data: welcomeResponse } = await apiService.surveySessionWelcomeGet(this.userSurveySlug);
        const {
          surveyName,
          orgstructureRequired,
          orgstructurePageOrder,
          companyId,
        } = welcomeResponse;
        this[setCompanyId](companyId);

        this.firstPageIsOrgstructure = orgstructureRequired && orgstructurePageOrder === 0;
        this[updateProgressBar]({ surveyName });
      } catch (err) {
        errorHandler(err);
      } finally {
        this[toggleLoading](false);
      }
    },
    async getSurveyAvailableLanguages() {
      this[toggleLoading](true);
      try {
        const { data } = await apiService.surveyLanguagesGet(this.userSurveySlug);
        this.languages = data;
      } catch (err) {
        errorHandler(err);
      } finally {
        this[toggleLoading](false);
      }
    },
  },
};
</script>
